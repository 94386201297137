import Vue from 'vue'
import VueRouter from 'vue-router'
import authMiddleware from '../middlewares/authMiddleware'
import Signin from '../views/Signin.vue'
import LostPassword from '../views/LostPassword.vue'
import Home from '../views/Home.vue'
import Maintenance from '../views/Maintenance.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/maintenance',
    name: 'maintenance.index',
    component: Maintenance
  },
  {
    path: '/auth',
    name: 'auth.signin',
    component: Signin
  },
  {
    path: '/auth/lost-id',
    name: 'auth.lost-id',
    component: LostPassword
  },
  {
    path: '/',
    name: 'home.index',
    meta: {
      middleware: authMiddleware
    },
    component: Home
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
