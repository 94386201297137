<template>
    <div>
        <div class="container mx-auto home__courses__list">
            <div class="home__courses__list__items position-relative py-4 flex flex-wrap justify-center" v-if="courses">
                <div v-for="(course, index) in courses" :key="index" class="w-full p-2"
                :class="{'md:w-1/3': !course.banner}">
                    <course-card :item="course" :theme-store="ThemeStore" v-if="!course.banner"/>
                    <banner-card :item="course" :theme-store="ThemeStore" v-else/>
                </div>
            </div>
        </div>
        <div class="container mx-auto my-6" v-if="pagesCount > 1">
            <courses-pagination :pages="pagesCount" :current="currentPage" @page-updated="fetch"/>
        </div>
    </div>
</template>

<script>
import CourseCard from './Card.vue';
import BannerCard from '@/components/Banner/Card.vue'
import CoursesPagination from '@/components/Partials/CoursesPagination.vue'
import { useThemeStore } from "@/store/ThemeStore";

import AuthMixin from '@/mixins/authMixin'
import UIMixin from '@/mixins/uiMixin'

export default {
    mixins: [AuthMixin, UIMixin],
    components: {
        CoursesPagination,
        CourseCard,
        BannerCard,
    },
    props: {
        filter: String,
    },
    data () {
        const ThemeStore = useThemeStore();
        return {
            ThemeStore: ThemeStore,
            thematicRefs: null,
            fetching: false,
            courses: [],
            coursesCount: 0,
            coursesPerPage: 0,
            currentPage: 0,
            pagesCount: 0,
        }
    },
    mounted() {
        this.fetch()
    },
    methods: {
        fetch(page) {
            if (this.filter == 'esse') {
                this.thematicRefs = 'residential,homecare'
            } else {
                this.thematicRefs = null // 'pdr,hes,peh,eeb,fdv'
            }

            const url = process.env.VUE_APP_BASE_URL + '/data/Scorm/Courses'
            const accessToken = this.$cookies.get('access_token')
            this.fetching = true

            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: 'Bearer ' + accessToken
            }

            switch (this.ThemeStore.client) {
            case 'aap':
            case 'hygie-recrutement':
            case 'azae-am':
            case 'azae-avs':
            case 'ubi':
                this.thematicRefs = ''
                break
            case 'aapp':
                this.thematicRefs = 'aapp'
                break
            default:
                if (this.filter == 'esse') {
                this.thematicRefs = 'residential,homecare'
                }
                break
            }

            this.$http.get(url, { headers: headers, params: {
            thematicRefs: this.thematicRefs,
            client: this.ThemeStore.client,
            page: page ?? 1
            } }).then(
                res => {
                    // detect if we get an array of parcours or a single parcours
                    if (res.data.courses) { // array of parcours
                        this.courses = res.data.courses
                        this.coursesCount = res.data.coursesCount
                        this.coursesPerPage = res.data.coursesPerPage
                        this.currentPage = res.data.currentPage
                        this.pagesCount = res.data.pagesCount
                    } else { // single parcours
                        this.currentParcours = res.data[0]
                        this.uniqueParcours = true
                    }
                    
                    this.fetching = false
                },
                error => {
                    this.fetching = false

                    switch (error.response.status) {
                        case 401:
                            this.refreshToken(() => {
                                this.fetch(page)
                            })
                            
                            return
                        case 503:
                            this.$router.push({ name: 'maintenance.index' })
                            break
                        default:
                            break
                    }
                }
            )
        },
    }
}
</script>
