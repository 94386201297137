<template>
    <div class="home__performances rounded-2xl" v-if="performances">
        <div class="home__performances__header rounded-t-2xl">
            <strong class="title">Mes indicateurs de performance</strong>
        </div>
        <div class="home__performances__content rounded-b-2xl">
            <section>
                <div class="title flex items-center">
                    <img src="@/assets/performances/working-conditions.png" class="mr-2" alt="">
                    <span>CONDITIONS DE TRAVAIL</span>
                </div>
                <div class="rating flex items-center"
                :style="{
                    background: performances.workingConditions.ratingBackgroundColor
                }">
                    <img src="@/assets/performances/arrow.png" alt="">
                    <span :style="{
                        color: performances.workingConditions.ratingColor
                    }">{{ performances.workingConditions.rating }}</span>
                </div>
                <div class="home__performances__content__bloc p-4">
                    <div class="lg:flex lg:items-start">
                        <div class="indicator mb-4 mx-auto lg:mr-4 lg:mx-0 lg:mb-0">
                            <img :src="performances.workingConditions.jaugeImg" alt="" class="jauge">
                        </div>
                        <p>{{ performances.workingConditions.text }}</p>
                    </div>
                    <div class="xl:flex xl:items-start">
                        <div class="home__performances__skills__content__bloc mt-4">
                            <div class="title text-center">
                                <span>Vos conditions de travail :</span>
                            </div>
                            <div class="skills__bloc md:grid md:grid-cols-3 lg:grid-cols-5 xl:flex xl:items-center">
                                <div class="skill card flex items-center justify-center"
                                :class="{
                                    skill__locked: !performances.workingConditions.holidaysWork.unlocked
                                }">
                                    <div class="content">
                                        <img :src="performances.workingConditions.holidaysWork.icon" alt="">
                                        <p v-html="performances.workingConditions.holidaysWork.text.replace(/\n/g, '<br />')" />
                                    </div>
                                </div>
                                <div class="skill card flex items-center justify-center"
                                :class="{
                                    skill__locked: !performances.workingConditions.nightWork.unlocked
                                }">
                                    <div class="content">
                                        <img :src="performances.workingConditions.nightWork.icon" alt="">
                                        <p v-html="performances.workingConditions.nightWork.text.replace(/\n/g, '<br />')" />
                                    </div>
                                </div>
                                <div class="skill card flex items-center justify-center"
                                :class="{
                                    skill__locked: !performances.workingConditions.partTimeWork.unlocked
                                }">
                                    <div class="content">
                                        <img :src="performances.workingConditions.partTimeWork.icon" alt="">
                                        <p v-html="performances.workingConditions.partTimeWork.text.replace(/\n/g, '<br />')" />
                                    </div>
                                </div>
                                <div class="skill card flex items-center justify-center"
                                :class="{
                                    skill__locked: !performances.workingConditions.report.unlocked
                                }">
                                    <div class="content">
                                        <img :src="performances.workingConditions.report.icon" alt="">
                                        <p v-html="performances.workingConditions.report.text.replace(/\n/g, '<br />')" />
                                    </div>
                                </div>
                                <div class="skill card flex items-center justify-center"
                                :class="{
                                    skill__locked: !performances.workingConditions.transfer.unlocked
                                }">
                                    <div class="content">
                                        <img :src="performances.workingConditions.transfer.icon" alt="">
                                        <p v-html="performances.workingConditions.transfer.text.replace(/\n/g, '<br />')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="home__performances__skills__content__bloc mt-4 xl:w-full xl:ml-4">
                            <div class="title text-center">
                                <span>Lieu de travail</span>
                            </div>
                            <div class="skills__bloc md:grid md:grid-cols-2 xl:flex xl:flex-col">
                                <div class="skill rectangle flex items-center justify-center lg:justify-start"
                                :class="{
                                    skill__locked: !performances.workingConditions.homeCare.unlocked
                                }">
                                    <div class="content lg:flex lg:items-center">
                                        <img :src="performances.workingConditions.homeCare.icon" alt="" class="lg:mr-2">
                                        <p v-html="performances.workingConditions.homeCare.text.replace(/\n/g, '<br />')" />
                                    </div>
                                </div>
                                <div class="skill rectangle flex items-center justify-center lg:justify-start"
                                :class="{
                                    skill__locked: !performances.workingConditions.residential.unlocked
                                }">
                                    <div class="content lg:flex lg:items-center">
                                        <img :src="performances.workingConditions.residential.icon" alt="" class="lg:mr-2">
                                        <p v-html="performances.workingConditions.residential.text.replace(/\n/g, '<br />')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="title flex items-center">
                    <img src="@/assets/performances/mobility.png" class="mr-2" alt="">
                    <span>MOBILITÉ</span>
                </div>
                <div class="rating flex items-center"
                :style="{
                    background: performances.mobility.ratingBackgroundColor
                }">
                    <img src="@/assets/performances/arrow.png" alt="">
                    <span :style="{
                        color: performances.mobility.ratingColor
                    }">{{ performances.mobility.rating }}</span>
                </div>
                <div class="home__performances__content__bloc p-4">
                    <div class="lg:flex lg:items-start">
                        <div class="indicator mb-4 mx-auto lg:mr-4 lg:mx-0 lg:mb-0">
                            <img :src="performances.mobility.jaugeImg" alt="" class="jauge">
                        </div>
                        <p>{{ performances.mobility.text }}</p>
                    </div>
                    <div class="xl:flex xl:items-start">
                        <div class="home__performances__skills__content__bloc mt-4">
                            <div class="title text-center">
                                <span>Votre mobilité :</span>
                            </div>
                            <div class="skills__bloc md:grid md:grid-cols-3 lg:grid-cols-5 xl:flex xl:items-center">
                                <div class="skill card flex items-center justify-center"
                                :class="{
                                    skill__locked: !performances.mobility.vehicles.publicTransports.unlocked
                                }">
                                    <div class="content">
                                        <img :src="performances.mobility.vehicles.publicTransports.icon" alt="">
                                        <p v-html="performances.mobility.vehicles.publicTransports.text.replace(/\n/g, '<br />')" />
                                    </div>
                                </div>
                                <div class="skill card flex items-center justify-center"
                                :class="{
                                    skill__locked: !performances.mobility.vehicles.car.unlocked
                                }">
                                    <div class="content">
                                        <img :src="performances.mobility.vehicles.car.icon" alt="">
                                        <p v-html="performances.mobility.vehicles.car.text.replace(/\n/g, '<br />')" />
                                    </div>
                                </div>
                                <div class="skill card flex items-center justify-center"
                                :class="{
                                    skill__locked: !performances.mobility.vehicles.drivingLicense.unlocked
                                }">
                                    <div class="content">
                                        <img :src="performances.mobility.vehicles.drivingLicense.icon" alt="">
                                        <p v-html="performances.mobility.vehicles.drivingLicense.text.replace(/\n/g, '<br />')" />
                                    </div>
                                </div>
                                <div class="skill card flex items-center justify-center"
                                :class="{
                                    skill__locked: !performances.mobility.vehicles.motorcycle.unlocked
                                }">
                                    <div class="content">
                                        <img :src="performances.mobility.vehicles.motorcycle.icon" alt="">
                                        <p v-html="performances.mobility.vehicles.motorcycle.text.replace(/\n/g, '<br />')" />
                                    </div>
                                </div>
                                <div class="skill card flex items-center justify-center"
                                :class="{
                                    skill__locked: !performances.mobility.vehicles.bike.unlocked
                                }">
                                    <div class="content">
                                        <img :src="performances.mobility.vehicles.bike.icon" alt="">
                                        <p v-html="performances.mobility.vehicles.bike.text.replace(/\n/g, '<br />')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="home__performances__skills__content__bloc mt-4 xl:w-full xl:ml-4">
                            <div class="title text-center">
                                <span>Distance et temps de trajets</span>
                            </div>
                            <div class="skills__bloc md:grid md:grid-cols-2 xl:flex xl:items-center">
                                <div class="skill card flex items-center justify-center lg:mx-auto"
                                :class="{
                                    skill__locked: !performances.mobility.maxTravelRadius.unlocked
                                }">
                                    <div class="content">
                                        <img :src="performances.mobility.maxTravelRadius.icon" alt="">
                                        <p v-html="performances.mobility.maxTravelRadius.text.replace(/\n/g, '<br />')" />
                                    </div>
                                </div>
                                <div class="skill card flex items-center justify-center lg:mx-auto"
                                :class="{
                                    skill__locked: !performances.mobility.maxTravelRadius.unlocked
                                }">
                                    <div class="content text-center">
                                        <p>Jusqu'a</p>
                                        <h2 v-html="performances.mobility.maxTravelRadius.value"></h2>
                                        <p>minutes</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="title flex items-center">
                    <img src="@/assets/performances/digital-skills.png" class="mr-2" alt="">
                    <span>COMPÉTENCES DIGITALES</span>
                </div>
                <div class="rating flex items-center"
                :style="{
                    background: performances.digital.ratingBackgroundColor
                }">
                    <img src="@/assets/performances/arrow.png" alt="">
                    <span :style="{
                        color: performances.digital.ratingColor
                    }">{{ performances.digital.rating }}</span>
                </div>
                <div class="home__performances__content__bloc p-4">
                    <div class="lg:flex lg:items-start">
                        <div class="indicator mb-4 mx-auto lg:mr-4 lg:mx-0 lg:mb-0">
                            <img :src="performances.digital.jaugeImg" alt="" class="jauge">
                        </div>
                        <p>{{ performances.digital.text }}</p>
                    </div>
                    <div class="xl:flex xl:items-start">
                        <div class="home__performances__skills__content__bloc mt-4">
                            <div class="title text-center">
                                <span>Compétences</span>
                            </div>
                            <div class="skills__bloc">
                                <div class="md:grid md:grid-cols-3">
                                    <div class="skill rectangle high flex items-center justify-center lg:justify-start mx-auto"
                                    :class="{
                                        skill__locked: !performances.digital.skills.internet.unlocked
                                    }">
                                        <div class="content lg:flex lg:items-center">
                                            <img :src="performances.digital.skills.internet.icon" alt="" class="lg:mr-2">
                                            <p v-html="performances.digital.skills.internet.text.replace(/\n/g, '<br />')" />
                                        </div>
                                    </div>
                                    <div class="skill rectangle high flex items-center justify-center lg:justify-start mx-auto"
                                    :class="{
                                        skill__locked: !performances.digital.skills.mailbox.unlocked
                                    }">
                                        <div class="content lg:flex lg:items-center">
                                            <img :src="performances.digital.skills.mailbox.icon" alt="" class="lg:mr-2">
                                            <p v-html="performances.digital.skills.mailbox.text.replace(/\n/g, '<br />')" />
                                        </div>
                                    </div>
                                    <div class="skill rectangle high flex items-center justify-center lg:justify-start mx-auto"
                                    :class="{
                                        skill__locked: !performances.digital.skills.sendEmail.unlocked
                                    }">
                                        <div class="content lg:flex lg:items-center">
                                            <img :src="performances.digital.skills.sendEmail.icon" alt="" class="lg:mr-2">
                                            <p v-html="performances.digital.skills.sendEmail.text.replace(/\n/g, '<br />')" />
                                        </div>
                                    </div>
                                </div>
                                <div class="md:grid md:grid-cols-3 lg:grid-cols-5 xl:flex xl:items-center">
                                    <div class="skill card flex items-center justify-center mx-auto"
                                    :class="{
                                        skill__locked: !performances.digital.skills.sms.unlocked
                                    }">
                                        <div class="content">
                                            <img :src="performances.digital.skills.sms.icon" alt="">
                                            <p v-html="performances.digital.skills.sms.text.replace(/\n/g, '<br />')" />
                                        </div>
                                    </div>
                                    <div class="skill card flex items-center justify-center mx-auto"
                                    :class="{
                                        skill__locked: !performances.digital.skills.admin.unlocked
                                    }">
                                        <div class="content">
                                            <img :src="performances.digital.skills.admin.icon" alt="">
                                            <p v-html="performances.digital.skills.admin.text.replace(/\n/g, '<br />')" />
                                        </div>
                                    </div>
                                    <div class="skill card flex items-center justify-center mx-auto"
                                    :class="{
                                        skill__locked: !performances.digital.skills.multimedia.unlocked
                                    }">
                                        <div class="content">
                                            <img :src="performances.digital.skills.multimedia.icon" alt="">
                                            <p v-html="performances.digital.skills.multimedia.text.replace(/\n/g, '<br />')" />
                                        </div>
                                    </div>
                                    <div class="skill card flex items-center justify-center mx-auto"
                                    :class="{
                                        skill__locked: !performances.digital.skills.download.unlocked
                                    }">
                                        <div class="content">
                                            <img :src="performances.digital.skills.download.icon" alt="">
                                            <p v-html="performances.digital.skills.download.text.replace(/\n/g, '<br />')" />
                                        </div>
                                    </div>
                                    <div class="skill card flex items-center justify-center mx-auto"
                                    :class="{
                                        skill__locked: !performances.digital.skills.people.unlocked
                                    }">
                                        <div class="content">
                                            <img :src="performances.digital.skills.people.icon" alt="">
                                            <p v-html="performances.digital.skills.people.text.replace(/\n/g, '<br />')" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="home__performances__skills__content__bloc mt-4 xl:w-full xl:ml-4">
                            <div class="title text-center">
                                <span>Matériel</span>
                            </div>
                            <div class="skills__bloc md:grid md:grid-cols-3 xl:flex xl:flex-col">
                                <div class="skill rectangle medium flex items-center justify-center lg:justify-start mx-auto"
                                    :class="{
                                        skill__locked: !performances.digital.hardware.computer.unlocked
                                    }">
                                    <div class="content lg:flex lg:items-center">
                                        <img :src="performances.digital.hardware.computer.icon" alt="" class="lg:mr-2">
                                        <p v-html="performances.digital.hardware.computer.text.replace(/\n/g, '<br />')" />
                                    </div>
                                </div>
                                <div class="skill rectangle medium flex items-center justify-center lg:justify-start mx-auto"
                                    :class="{
                                        skill__locked: !performances.digital.hardware.tablet.unlocked
                                    }">
                                    <div class="content lg:flex lg:items-center">
                                        <img :src="performances.digital.hardware.tablet.icon" alt="" class="lg:mr-2">
                                        <p v-html="performances.digital.hardware.tablet.text.replace(/\n/g, '<br />')" />
                                    </div>
                                </div>
                                <div class="skill rectangle medium flex items-center justify-center lg:justify-start mx-auto"
                                    :class="{
                                        skill__locked: !performances.digital.hardware.smartphone.unlocked
                                    }">
                                    <div class="content lg:flex lg:items-center">
                                        <img :src="performances.digital.hardware.smartphone.icon" alt="" class="lg:mr-2">
                                        <p v-html="performances.digital.hardware.smartphone.text.replace(/\n/g, '<br />')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { useUserStore } from "@/store/UserStore"

export default {
    name: 'Performances',
    data () {
        const UserStore = useUserStore();
        return {
            UserStore: UserStore,
            performances: {}
        }
    },
    mounted() {
        this.performances = this.UserStore.performances;
    }
}
</script>