
import { useThemeStore } from "@/store/ThemeStore";

export default {
    data () {
        const ThemeStore = useThemeStore();   
        return {
            ThemeStore: ThemeStore,
            fetchingTheme: true
        }
    },
    computed: {
        deviceType() {
            const ua = navigator.userAgent;
            if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
                return "tablet";
            }
            else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
                return "mobile";
            }
            return "desktop";
        },
        layoutBackground() {
            if (this.ThemeStore.pageBackground && this.ThemeStore.pageBackground.url) {
                return {
                    'background-image': 'url(' + this.ThemeStore.pageBackground.url + ')'
                }
            } else if (this.ThemeStore.pageBackground
                && this.ThemeStore.pageBackground.backgroundColorTop
                && this.ThemeStore.pageBackground.backgroundColorBot) {
                return {
                    'background-image': 'linear-gradient(to bottom, '
                    + this.ThemeStore.pageBackground.backgroundColorTop + ' 0%, '
                    + this.ThemeStore.pageBackground.backgroundColorBot + ' 25%)'
                }
            }
        },
    },
    methods: {
        fetchUITheme(client) {
            let url = process.env.VUE_APP_BASE_URL + '/data/scorm/theme'

            this.fetchingTheme = true

            if (process.env.VUE_APP_CLIENT_NAME) {
                client = process.env.VUE_APP_CLIENT_NAME
            }

            if (!client) {
                const host = window.location.host
                client = host.split('.')[0]
            }

            client = client ?? 'aapp'
        
            url += '?client=' + client

            this.$http.get(url).then(
                res => {
                    res = res.data
                    const ThemeStore = useThemeStore();

                    ThemeStore.setClient(client)

                    if (res.homePage) {
                        if (res.homePage.background) {
                            ThemeStore.setPageBackground(res.homePage.background)
                        } else if (res.homePage.backgroundColorBot
                            && res.homePage.backgroundColorTop) {
                            ThemeStore.setPageBackground({
                                backgroundColorBot: res.homePage.backgroundColorBot,
                                backgroundColorTop: res.homePage.backgroundColorTop
                            })
                        }

                        if (res.homePage.title) {
                            ThemeStore.setPageTitle(res.homePage.title)
                        }

                        if (res.homePage.subTitle) {
                            ThemeStore.setPageSubTitle(res.homePage.subTitle)
                        }

                        if (res.homePage.logo) {
                            ThemeStore.setBoxedLogo(res.homePage.logo)
                            ThemeStore.setLogo(res.homePage.logo)
                        }
                    } else {
                        if (res.pageBackground) {
                            ThemeStore.setPageBackground(res.pageBackground)
                        } else if (res.backgroundColorBot && res.backgroundColorTop) {
                            ThemeStore.setPageBackground({
                                backgroundColorBot: res.backgroundColorBot,
                                backgroundColorTop: res.backgroundColorTop
                            })
                        }

                        if (res.pageTitle) {
                            ThemeStore.setPageTitle(res.pageTitle)
                        }

                        if (res.boxedLogo) {
                            ThemeStore.setBoxedLogo(res.boxedLogo)
                        }

                        if (res.pageSubTitle) {
                            ThemeStore.setPageSubTitle(res.pageSubTitle)
                        }

                        if (res.logo) {
                            ThemeStore.setLogo(res.logo)
                        }
                    }

                    if (res.loginPage) {
                        ThemeStore.setLoginPage(res.loginPage)
                    }
                    
                    if (res.visuals) {
                        ThemeStore.setVisuals(res.visuals)
                    }

                    if (res.lostPassword) {
                        ThemeStore.setLostPassword(res.lostPassword)
                    }

                    ThemeStore.setClientName(res.clientName)
                    ThemeStore.setPartners(res.partners)

                    this.fetchingTheme = false
                },
                error => {
                    this.fetchingTheme = false

                    if (error.response.status == 404) {
                        this.fetchUITheme('aapp') // use default theme
                    }
                }
            )
        }
    }
}